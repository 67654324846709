import React, {useEffect, useState} from 'react';
import defaultAvatar from "../default-avatar.png"
import axios from "axios";
const Company = () => {
    const [PG,setPG] = useState(0)
    const [reviews, setReviews] = useState([])
    const [reviewsIL, setReviewsIL] = useState(false)

    const url = new URL(document.location.href);
    const companyID = Number(url.searchParams.get('id'));
    const getReviews = () => {
        axios.get(`https://24excrypto.com/reviews/${companyID}`)
            .then(res => {
                setReviews(res.data)
                setReviewsIL(true)
            })
    }

    const [companyData, setCompanyData] = useState([])
    const [companyDataIL, setCompanyDataIL] = useState(false)
    const getCompanyData = () => {
        axios.get(`https://24excrypto.com/company/${companyID}`)
            .then(res => {
                setCompanyData(res.data[0])
                setCompanyDataIL(true)
            })
    }

    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [username, setUsername] = useState("")
    const postReview = () => {
        axios.post(`https://24excrypto.com/reviews/${companyID}`, {
                "company": companyID,
                "username": username,
                "text": text,
                "score": PG,
                "link": title
        })
            .then(res => {
                document.location.reload();
                // Очистите значения полей или выполните другие необходимые действия
                // setText("");
                // setTitle("");
                // setUsername("");
                // getReviews();
            })
            .catch(error => {
                console.error(error);
            });
    }


    useEffect(() => {
        getReviews()
        getCompanyData()
    }, [])


    let reviewsGrade = reviews.length > 0 ? Math.floor10(reviews.reduce(function(a, b){return(a+b.score)}, 0) / reviews.length, -2) : 0

    return (
        <div className="main">
            <div className="listing-bg2">
                <h1 className="listing-title-cp">{companyData.name}</h1>
            </div>
            <div className="aboutcompany">

                <div className="review">
                    <p className="grade">{reviewsGrade}</p>
                    <div className="grade-stars">
                        {reviewsGrade > 0.55 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                        {reviewsGrade > 1.65 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                        {reviewsGrade > 2.65 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                        {reviewsGrade > 3.65 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                        {reviewsGrade > 4.75 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                    </div>
                    <p className="grade-total">({reviews.length})</p>
                </div>

                <img src={`https://24excrypto.com/images/${companyData.image}`} className="company-img" alt=""/>
                <div className="company-subtitle">Контактные данные</div>
                <div className="company-ul">{companyData.contacts}</div>
                <div className="company-subtitle">О брокере</div>
                <p className="company-text">{companyData.about_company}</p>
                <div className="company-subtitle">Торговые условия</div>

                <p className="company-text">{companyData.trade_terms}</p>


                <div className="company-subtitle">Дополнительный сервис</div>
                <p className="company-text">{companyData.summary}</p>

            </div>
            <div className="reviews">
                <div className="reviews-title">Отзывы о компании {companyData.name}</div>

                {reviewsIL ? reviews.map(review =>
                    <div className="review-item">
                        <img src={defaultAvatar} alt="" className="reviewer-avatar"/>
                        <div className="review-child">
                            <div className="review-item__title">
                                <p className="rewiewer-title">{review.link}</p>
                                <div className="grade-stars">
                                    {review.score > 0.95 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                                    {review.score > 1.95 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                                    {review.score > 2.95 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                                    {review.score > 3.95 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                                    {review.score > 4.95 ? <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star"/> : <img src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star"/> }
                                </div>
                            </div>
                            <p className="rewiewer-name">{review.username}</p>
                            <p className="rewiever-text">{review.text}</p>
                        </div>

                    </div>
                ) : ""}

                <div className="reviews-title2">Оставьте ваш отзыв</div>
                <div className="reviews-writegrade">Ваш рейтинг
                    <div className="grade-stars">
                        {PG > 0.55 ? <img onClick={() => setPG(1)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star2"/> : <img onClick={() => setPG(1)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star2"/> }
                        {PG > 1.65 ? <img onClick={() => setPG(2)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star2"/> : <img onClick={() => setPG(2)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star2"/> }
                        {PG > 2.65 ? <img onClick={() => setPG(3)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star2"/> : <img onClick={() => setPG(3)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star2"/> }
                        {PG > 3.65 ? <img onClick={() => setPG(4)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star2"/> : <img onClick={() => setPG(4)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star2"/> }
                        {PG > 4.75 ? <img onClick={() => setPG(5)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-colored.svg" alt="" className="grade-star2"/> : <img onClick={() => setPG(5)} src="https://finotzyvy.com/wp-content/plugins/functionality-for-directory-theme/public/images/star-empty.svg" alt="" className="grade-star2"/> }
                    </div>
                </div>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder={"Заголовок вашего отзыва"} className="review-input "/>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder={"Ваше имя"} className="review-input "/>
                <textarea value={text} onChange={(e) => setText(e.target.value)} placeholder={"Ваш отзыв"} className="review-input review-input-text"/>
                <button className="review-send" onClick={postReview}>Отправить</button>




            </div>

        </div>
    );
};

export default Company;