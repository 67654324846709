import React from 'react';
import logo from './logo.png'
import {NavLink} from "react-router-dom";
const Footer = () => {
    return (
        <footer className="footer-bottom-container">
            <div className="footer-bottom-inner">
                    <div className="footer-column">
                                        <aside className="footer_logocop">
                                                    <a href="/">
                                                        <img
                                                            className="logo-footer"
                                                            src={logo}
                                                            alt=""
                                                        />
                                                    </a>
                                                <div className="dt-footer-copyright">© 2023 OtzyvyFin</div>
                                        </aside>
                                    </div>
                    <div className="footer-column">
                        <NavLink className="footer_link" to="/politika-konfidentsialnosti">
                            Политика обработки персональных данных
                        </NavLink>
                    </div>
                    <div className="footer-column">
                        <div className="footer_subtitle">Рейтинги компаний</div>
                        <NavLink className="footer_link" to="/">
                            Криптобиржи и форекс брокеры
                        </NavLink>
                    </div>
                    <div className="footer-column">
                        <div className="footer_subtitle">Контакты</div>
                        <div className="footer_link">
                            Email: Info@otzyvyfin.com
                        </div>
                    </div>
            </div>
        </footer>

    );
};

export default Footer;