import React from 'react';
import {NavLink} from "react-router-dom";
import logo from "./logo.png"
const Header = () => {
    return (
        <header>
            <nav>
                <NavLink to="/"><img src={logo} alt="Logo" className="header-img"/></NavLink>
                <div className="nav-child">
                    <NavLink to="/" className="nav-text">Криптобиржи и форекс брокеры</NavLink>
                    {/*<NavLink to="/" className="nav-text">Инвест-компании</NavLink>*/}
                    {/*<NavLink to="/" className="nav-text">Рейтинг КПК</NavLink>*/}
                </div>

            </nav>
        </header>
    );
};

export default Header;