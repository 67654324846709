import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./app.css"
import Header from "./components/header";
import Main from "./components/main";
import Company from "./components/company";
import Footer from "./components/footer";
import Policy from "./components/policy";
const App = () => {
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                    <Route path="/politika-konfidentsialnosti" element={<Policy/>} />
                    <Route path="/" element={<Main/>} />
                    <Route path="/company" element={<Company/>} />
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
};

export default App;