import React from 'react';

const Policy = () => {
    return (
        <div id="main" className="site-main_cont">
            <div id="main-content" className="main-content row">
                <div className="entry-header">
                    <h1 className="entry-title">Политика обработки персональных данных</h1>
                </div>
                <div id="primary" className="content-area sidebar-no clearfix ">
                    <div id="content" className="site-content " role="main">
                        <article
                            id="post-2784"
                            className="post-2784 page type-page status-publish hentry"
                        >
                            <h1 className="hidden" />
                            <div className="entry-content">
                                <div id="entry-content-wrapper" className="clearfix">
                                    <p>
                                        <strong>Редакция от 08 ноября 2019 года</strong>
                                    </p>
                                    <p>
                                        Настоящая Политика обработки персональных данных (далее также–
                                        «Политика») разработана администратором доменного имени
                                        otzyvyfin.com (далее также – Администрация Сайта или мы) в целях
                                        соблюдения требований Федерального закона от 27.07.2006 № 152-ФЗ
                                        «О персональных данных».
                                    </p>
                                    <p>
                                        Политика устанавливает перечень обрабатываемых нами персональных
                                        данных, принципы, цели и способы обработки персональных данных,
                                        а также сведения о реализуемых требованиях к защите персональных
                                        данных.
                                    </p>
                                    <p>
                                        Политика публикуется в открытом доступе в сети интернет по
                                        адресу: otzyvyfin.com/politika-konfidentsialnosti.
                                    </p>
                                    <ol>
                                        <li>ОСНОВНЫЕ ПОНЯТИЯ</li>
                                    </ol>
                                    <p>
                                        Персональные данные — любая информация, относящаяся к прямо или
                                        косвенно определенному или определяемому физическому лицу
                                        (субъекту персональных данных);
                                    </p>
                                    <p>
                                        Обработка персональных данных — любое действие (операция) или
                                        совокупность действий (операций), совершаемых с использованием
                                        средств автоматизации или без использования таких средств с
                                        персональными данными, включая сбор, запись, систематизацию,
                                        накопление, хранение, уточнение (обновление, изменение),
                                        извлечение, использование, передачу (распространение,
                                        предоставление, доступ), обезличивание, блокирование, удаление,
                                        уничтожение персональных данных;
                                    </p>
                                    <p>
                                        Автоматизированная обработка персональных данных — обработка
                                        персональных данных с помощью средств вычислительной техники;
                                    </p>
                                    <p>
                                        Распространение персональных данных — действия, направленные на
                                        раскрытие персональных данных неопределенному кругу лиц;
                                    </p>
                                    <p>
                                        Предоставление персональных данных — действия, направленные на
                                        раскрытие персональных данных определенному лицу или
                                        определенному кругу лиц;
                                    </p>
                                    <p>
                                        Трансграничная передача персональных данных — передача
                                        персональных данных на территорию иностранного государства
                                        органу власти иностранного государства, иностранному физическому
                                        лицу или иностранному юридическому лицу.
                                    </p>
                                    <p>Сайт — otzyvyfin.com (далее также — Сайт)</p>
                                    <p>
                                        Администрация Сайта — Администратор доменного имени
                                        otzyvyfin.com
                                    </p>
                                    <p>
                                        Пользователь – лицо, имеющее доступ к Сайту посредством сети
                                        Интернет и использующее функциональные возможности Сайта,
                                        включая возможность размещения отзывов по соответствующей
                                        тематике, в том числе предоставляющее свои персональные данные
                                        Администрации Сайта (далее также – Субъект персональных данных).
                                    </p>
                                    <p>
                                        В настоящей Политике используются также иные понятия в значении,
                                        указанном в Федеральном законе от 27.07.2006 № 152-ФЗ «О
                                        персональных данных».
                                    </p>
                                    <ol start={2}>
                                        <li>СУБЪЕКТЫ, ДАННЫЕ КОТОРЫХ МЫ ОБРАБАТЫВАЕМ</li>
                                    </ol>
                                    <p>
                                        Администрация Сайта обрабатывает персональные данные
                                        Пользователей Сайта.
                                    </p>
                                    <ol start={3}>
                                        <li>
                                            ПЕРЕЧЕНЬ ПЕРСОНАЛЬНЫХ И ТЕХНИЧЕСКИХ ДАННЫХ, КОТОРЫЕ
                                            АДМИНИСТРАЦИЯ САЙТА ОБРАБАТЫВАЕТ В СООТВЕТСТВИИ С ПОЛИТИКОЙ
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            Пользователи могут передавать Администрации Сайта при
                                            заполнении формы оставления Отзыва на Сайте, а также при
                                            направлении сообщений по электронной почте следующие данные:
                                            <ul>
                                                <li>Имя</li>
                                                <li>Адрес электронной почты</li>
                                                <li>Изображение (фотография)</li>
                                                <li>
                                                    Иные персональные данные, которые могут быть переданы
                                                    Пользователем по своему усмотрению.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Обработка специальных категорий персональных данных,
                                            касающихся расовой, национальной принадлежности, политических
                                            взглядов, религиозных или философских убеждений и т.п, а также
                                            биометрических данных Администрацией Сайта не осуществляется.
                                        </li>
                                        <li>
                                            При использовании Пользователями Сайта Администрация Сайта
                                            получает и обрабатывает технические данные, которые
                                            автоматически передаются устройством Пользователя, с помощью
                                            которого он использует Сайт, в том числе:
                                            <ul>
                                                <li>технические характеристики устройства Пользователя</li>
                                                <li>IP-адрес</li>
                                                <li>
                                                    информация, сохраненная в файлах «cookies», которые были
                                                    отправлены на устройство Пользователя,
                                                </li>
                                                <li>информация о браузере,</li>
                                                <li>дата и время доступа к Сайту,</li>
                                                <li>
                                                    адреса запрашиваемых страниц и иная подобная информацию.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <p>
                                        В любое время Пользователь можете изменить параметры в
                                        настройках своего браузера таким образом, чтобы браузер перестал
                                        сохранять файлы cookie, а также оповещал об отправке. В этом
                                        случае некоторые сервисы и функции Сайта могут перестать
                                        работать или работать некорректно.
                                    </p>
                                    <ol start={4}>
                                        <li>
                                            ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                            <ul>
                                                <li>
                                                    Администрация Сайта осуществляет обработку персональных
                                                    данных в следующих целях:
                                                    <ul>
                                                        <li>
                                                            исполнения заключаемого Администрацией Сайта с
                                                            Пользователем договора (Пользовательского соглашения),
                                                            связанного с созданием и публикацией на Сайте отзыва
                                                            Пользователя, переписки с ним (по вопросам,
                                                            возникающим в связи с размещением отзыва Пользователя
                                                            либо при необходимости направления ответа на запрос
                                                            Пользователя)
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>
                                        4.1.2. иных законных целях, которые могут быть указаны в
                                        договорах, соглашениях, заключаемых с Пользователем либо при
                                        даче отдельного согласия на обработку персональных данных.
                                    </p>
                                    <ul>
                                        <li>
                                            Администрация Сайта осуществляет обработку технических данных
                                            Пользователя в следующих целях:
                                            <ul>
                                                <li>
                                                    обеспечения функционирования и безопасности Сайта, его
                                                    оптимизации.
                                                </li>
                                                <li>показа Пользователям рекламных объявлений</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ol start={5}>
                                        <li>
                                            ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                            <ul>
                                                <li>
                                                    обработка персональных данных осуществляется
                                                    Администрацией Сайта на законной и справедливой основе;
                                                </li>
                                                <li>
                                                    обработка персональных данных ограничивается достижением
                                                    конкретных, заранее определенных и законных целей;
                                                </li>
                                                <li>
                                                    не допускается обработка персональных данных,
                                                    несовместимая с целями сбора персональных данных;
                                                </li>
                                                <li>
                                                    не допускается объединение баз данных, содержащих
                                                    персональные данные, обработка которых осуществляется в
                                                    целях, несовместимых между собой;
                                                </li>
                                                <li>
                                                    содержание и объем обрабатываемых персональных данных
                                                    соответствует заявленным целям обработки. Не допускается
                                                    избыточность обрабатываемых персональных данных по
                                                    отношению к заявленным целям их обработки;
                                                </li>
                                                <li>
                                                    при обработке персональных данных обеспечиваются точность
                                                    персональных данных, их достаточность, а в необходимых
                                                    случаях и актуальность по отношению к целям обработки
                                                    персональных данных. Администрацией Сайта принимаются
                                                    необходимые меры либо обеспечивается их принятие по
                                                    удалению или уточнению неполных или неточных персональных
                                                    данных;
                                                </li>
                                                <li>
                                                    хранение персональных данных осуществляется в форме,
                                                    позволяющей определить субъекта персональных данных, не
                                                    дольше, чем того требуют цели обработки персональных
                                                    данных, если срок хранения персональных данных не
                                                    установлен федеральным законом, договором, стороной
                                                    которого, выгодоприобретателем по которому является
                                                    субъект персональных данных;
                                                </li>
                                                <li>
                                                    обрабатываемые персональные данные уничтожаются либо
                                                    обезличиваются по достижении целей обработки или в случае
                                                    утраты необходимости в достижении этих целей, если иное не
                                                    предусмотрено федеральным законом.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <ol start={6}>
                                        <li>
                                            СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ОБРАБОТКУ. ИНЫЕ УСЛОВИЯ И СПОСОБЫ
                                            ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </li>
                                    </ol>
                                    <p>
                                        6.1. Обработка персональных данных Администрацией Сайта
                                        осуществляется с согласия Пользователя Сайта на обработку его
                                        персональных данных, если иное не предусмотрено
                                        законодательством Российской Федерации в области персональных
                                        данных.
                                    </p>
                                    <p>
                                        6.2. Без согласия субъекта персональных данных Администрация
                                        Сайта не раскрывает третьим лицам и не распространяет
                                        персональные данные, если иное не предусмотрено федеральным
                                        законом.
                                    </p>
                                    <p>
                                        6.3. Оставаясь на Сайте и используя функциональные возможности
                                        Сайта, Пользователь дает Администрации Сайта добровольное
                                        согласие на обработку в соответствии с данной Политикой его
                                        персональных данных, а также технических данных. Указанное
                                        согласие является конкретным, информированным и осознанным,
                                        дается Пользователем свободно своей волей и в своем интересе.
                                        Согласие действует до дня его отзыва в письменной форме.
                                    </p>
                                    <p>
                                        6.4. В подтверждение принятия условий настоящей Политики в части
                                        положений об обработке персональных данных в отдельных сервисах
                                        Сайта Пользователь осуществляет проставление соответствующей
                                        отметки.
                                    </p>
                                    <p>
                                        6.5. Размещая свой отзыв на Сайте, Пользователь осознает и
                                        желает наступления последствий в виде предоставления доступа
                                        неограниченному кругу лиц к его персональным данным (раскрытие),
                                        указанным в п. 3.1.1, 3.1.3, 3.1.4 настоящей Политики, которые
                                        становятся общедоступными.
                                    </p>
                                    <p>
                                        6.6. Пользователи, не желающие, чтобы их Персональные данные
                                        обрабатывались Администрацией Сайта, раскрывались или
                                        распространялись, не должны предоставлять их, а также совершать
                                        действия, в результате которых согласие на обработку
                                        персональных данных считается выраженным , а данные могут быть
                                        предоставлены, раскрыты или распространены.
                                    </p>
                                    <p>
                                        6.7. Администрация Сайта обеспечивает хранение персональных
                                        данных, включая материальные носители персональных данных с
                                        соблюдением условий, обеспечивающих сохранность персональных
                                        данных и исключающих несанкционированный доступ к ним.
                                    </p>
                                    <p>
                                        6.8. Хранение персональных данных граждан Российской Федерации
                                        производится с использованием базы данных, находящейся на
                                        территории Российской Федерации, в соответствии с ч. 5 ст. 18
                                        Федерального закона «О персональных данных».
                                    </p>
                                    <p>
                                        6.9. Администрация Сайта осуществляет обработку персональных
                                        данных следующими способами: сбор, запись, систематизация,
                                        накопление, хранение, уточнение (обновление, изменение),
                                        извлечение, использование, передача (распространение,
                                        предоставление, доступ), обезличивание, блокирование, удаление и
                                        уничтожение персональных данных.
                                    </p>
                                    <p>
                                        6.10. Администрация Сайта осуществляет обработку персональных
                                        данных с использованием средств автоматизации и без их
                                        использования. При этом выполняет требования к
                                        автоматизированной и неавтоматизированной обработке персональных
                                        данных, предусмотренные Федеральным законом РФ «О персональных
                                        данных» №152-ФЗ от 27 июля 2006 г. и принятыми в соответствии с
                                        ним нормативными правовыми актами.
                                    </p>
                                    <p>
                                        6.11. Администрация Сайта не принимает решений, порождающих
                                        юридические последствия или иным образом затрагивающих права и
                                        законные интересы субъектов персональных данных на основании
                                        исключительно автоматизированной обработки персональных данных.
                                    </p>
                                    <p>
                                        6.12. Администрация Сайта не осуществляет трансграничную
                                        передачу персональных данных.
                                    </p>
                                    <ol start={7}>
                                        <li>
                                            ПРАВА И ОБЯЗАННОСТИ АДМИНИСТРАЦИИ САЙТА И ПОЛЬЗОВАТЕЛЕЙ КАК
                                            СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>Администрация Сайта имеет право:</li>
                                    </ul>
                                    <ul>
                                        <li>
                                            получать от субъекта персональных данных достоверные
                                            информацию и/или документы, содержащие персональные данные;
                                        </li>
                                        <li>
                                            требовать от субъекта персональных данных своевременного
                                            уточнения предоставленных персональных данных.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>Администрация Сайта обязана:</li>
                                    </ul>
                                    <ul>
                                        <li>
                                            обрабатывать персональные данные в порядке, установленном
                                            действующим законодательством РФ и настоящей Политикой;
                                        </li>
                                        <li>
                                            рассматривать обращения субъекта персональных данных по
                                            вопросам обработки персональных данных и давать мотивированные
                                            ответы;
                                        </li>
                                        <li>
                                            предоставлять субъекту персональных данных возможность
                                            безвозмездного доступа к его персональным данным;
                                        </li>
                                        <li>
                                            принимать меры по уточнению, уничтожению персональных данных
                                            субъекта персональных данных в связи с его обращением с
                                            законными и обоснованными требованиями;
                                        </li>
                                        <li>
                                            организовывать защиту персональных данных в соответствии с
                                            требованиями законодательства РФ.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>Пользователь имеет право:</li>
                                    </ul>
                                    <ul>
                                        <li>
                                            на полную информацию о&nbsp;его&nbsp;персональных данных,
                                            обрабатываемых Администрацией Сайта;
                                        </li>
                                        <li>
                                            на доступ к&nbsp;его персональным данным, включая право
                                            на&nbsp;получение копии любой записи, содержащей его
                                            персональные данные, за&nbsp;исключением случаев,
                                            предусмотренных федеральным законом;
                                        </li>
                                        <li>
                                            на уточнение персональных данных, их&nbsp;блокирование или
                                            уничтожение в&nbsp;случаях, если персональные данные являются
                                            неполными, устаревшими, неточными, незаконно полученными или
                                            не&nbsp;являются необходимыми для&nbsp;заявленной&nbsp;цели
                                            обработки;
                                        </li>
                                        <li>
                                            на отзыв согласия на&nbsp;обработку персональных данных;
                                        </li>
                                        <li>
                                            на принятие предусмотренных законом мер по&nbsp;защите своих
                                            прав;
                                        </li>
                                        <li>
                                            на осуществление иных прав, предусмотренных законодательством
                                            РФ.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>Пользователь обязан:</li>
                                    </ul>
                                    <ul>
                                        <li>
                                            предоставлять Администрации Сайта только достоверные данные о
                                            себе;
                                        </li>
                                        <li>
                                            предоставлять документы, содержащие персональные данные в
                                            объеме, необходимом для цели обработки;
                                        </li>
                                        <li>
                                            сообщать Администрации Сайта об уточнении (обновлении,
                                            изменении) своих персональных данных.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            Пользователи, передавшие Администрации Сайта недостоверные
                                            сведения о себе, либо сведения о другом субъекте персональных
                                            данных без согласия последнего, несут ответственность в
                                            соответствии с применимым законодательством.
                                        </li>
                                    </ul>
                                    <ol start={8}>
                                        <li>
                                            ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                            <ul>
                                                <li>согласие на обработку персональных данных;</li>
                                                <li>
                                                    действующее законодательство РФ, иные нормативно-правовые
                                                    акты, настоящая Политика.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <ol start={9}>
                                        <li>
                                            АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ
                                            ДАННЫХ. ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ.
                                            <ul>
                                                <li>
                                                    При достижении целей обработки персональных данных, а
                                                    также в случае отзыва субъектом персональных данных
                                                    Согласия, персональные данные подлежат уничтожению, если:
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>
                                        — Администрация Сайта не вправе осуществлять обработку без
                                        Согласия субъекта персональных данных;
                                    </p>
                                    <p>
                                        — иное не предусмотрено договором, стороной которого,
                                        выгодоприобретателем или поручителем по которому является
                                        субъект персональных данных;
                                    </p>
                                    <p>
                                        — иное не предусмотрено иным соглашением между Администрацией
                                        Сайта и субъектом персональных данных.
                                    </p>
                                    <ul>
                                        <li>Администрация Сайта сообщит Пользователю информацию</li>
                                    </ul>
                                    <p>
                                        — об осуществляемой ей обработке персональных данных такого
                                        субъекта;
                                    </p>
                                    <p>
                                        — внесет необходимые изменения в его персональные данные, если
                                        он подтвердит, что они являются неполными, неточными или
                                        неактуальными;
                                    </p>
                                    <p>
                                        — прекратит обработку персональных данных при отзыве согласия
                                        Пользователем, если для обработки персональных данных не будет
                                        иных правовых оснований, предусмотренных законодательством;
                                    </p>
                                    <p>
                                        — уничтожит персональные данные, если будет подтверждено, что
                                        они незаконно получены или не соответствуют заявленным целям
                                        обработки;
                                    </p>
                                    <p>
                                        — ответит на вопросы, касающиеся персональных данных, которые
                                        обрабатывает.
                                    </p>
                                    <ul>
                                        <li>
                                            Действия, указанные в п. 9.2. Политики, Администрация Сайта
                                            осуществляет по запросу Пользователя, оформленному в
                                            соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О
                                            персональных данных». Если в запросе Пользователя не отражены
                                            все необходимые сведения или Пользователь не обладает правами
                                            доступа к запрашиваемой информации, то ему будет направлен
                                            мотивированный отказ.
                                        </li>
                                        <li>
                                            Информация, касающаяся обработки персональных данных
                                            предоставляется в случаях, в объеме, в порядке и в сроки,
                                            предусмотренные действующим законодательством РФ.
                                        </li>
                                        <li>
                                            По вопросам, связанным с данной Политикой, можно связаться с
                                            Администрацией Сайта по адресу электронной почты, размещенному
                                            на Сайте.
                                        </li>
                                    </ul>
                                    <ol start={10}>
                                        <li>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</li>
                                    </ol>
                                    <p>
                                        10.1. Настоящая Политика действует бессрочно с момента
                                        размещения ее актуальной версии на Сайте и до ее замены новой
                                        версией. Администрация Сайта вправе вносить изменения в Политику
                                        по своему усмотрению в любое время. Продолжая пользоваться
                                        Сайтом после изменения Политики, Пользователь подтверждает
                                        согласие с внесенными изменениями и принимает актуальную
                                        редакцию Политики.
                                    </p>
                                    <p>
                                        10.2. Все отношения, касающиеся обработки персональных данных,
                                        не получившие отражения в настоящей Политике, регулируются
                                        положениями законодательства РФ.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Policy;