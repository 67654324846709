import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import axios from "axios";

const CompanyItem = (props) => {
    const [reviews, setReviews] = useState([])
    const getReviews = () => {
        axios.get(`https://24excrypto.com/reviews/${props.id}`)
            .then(res => {
                setReviews(res.data)
            })
    }
    useEffect(() => {
        getReviews()
    }, [])
    let reviewsGrade = reviews.length > 0 ? Math.floor10(reviews.reduce(function(a, b){return(a+b.score)}, 0) / reviews.length, -1) : 0

    return (
        <div className="listing-main">

            <NavLink to={`/company?id=${props.id}`} className="listing-main__title listing-main-text">
                <img src={`https://24excrypto.com/images/${props.image}`} className="listing-main-img" alt=""/>
                {props.name}
            </NavLink>
            <div className="listing-main__reviews listing-main-text"> <div className="mobile_subtitle">Отзывы:</div> <p className="listing-main__reviews_text">{reviewsGrade}</p></div>
            <div className="listing-header__site listing-main-text"> <div className="mobile_subtitle">Ссылка:</div><a className="listing-header__link" href={props.link}>{props.link}</a></div>
            <div className="listing-header__mindep listing-main-text"> <div className="mobile_subtitle">Мин. депозит:</div> {props.minimal_deposit}$</div>
            <div className="listing-header__leverage listing-main-text"> <div className="mobile_subtitle">Плечо:</div> {props.leverage}</div>
        </div>
    );
};

export default CompanyItem;