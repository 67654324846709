import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import axios from "axios";
import CompanyItem from "./companyItem";

const Main = () => {
    const [companies, setCompanies] = useState([])
    const [companiesIL, setCompaniesIL] = useState(false)
    const getCompanies = () => {
        axios.get("https://24excrypto.com/companies")
            .then(res => {
                setCompanies(res.data)
                setCompaniesIL(true)
            })
    }
    useEffect(() => {
        getCompanies()
    }, [])
    return (
        <div className="main">
            <div className="listing-bg">
                <h1 className="listing-title">Криптобиржи и форекс брокеры</h1>
            </div>
            <div className="listing">
                <div className="listing-header">
                    <div className="listing-header__title listing-header-text">Компании</div>
                    <div className="listing-header__reviews listing-header-text">Отзывы</div>
                    <div className="listing-header__site listing-header-text">Сайт</div>
                    <div className="listing-header__mindep listing-header-text">Мин. депозиты</div>
                    <div className="listing-header__leverage listing-header-text">Кредитное плечо</div>
                </div>

                {companiesIL ? companies.map(company =>
                    <CompanyItem id={company.id} image={company.image} name={company.name} link={company.link} minimal_deposit={company.minimal_deposit} leverage={company.leverage} />


                ) : ""}





            </div>
            <div className="db-listing-category"><p><strong>Форекс Брокеры</strong> – это компании, с помощью
                которых трейдеры торгуют на валютном рынке. Подобных организаций много, поэтому для правильного
                подбора важно учитывать не только технические особенности и скорость обработки данных, но и <strong>рейтинг
                    Форекс Брокеров</strong>.</p><p>Представленный список составлен на основе отзывов клиентов и
                ключевых параметров компаний – лицензий, способов ввода/вывода средств, размера комиссий, опыта
                работы и наличия опций для торговли, как валютой, так и с помощью других финансовых
                инструментов.</p><p>Мы даем возможность самостоятельной оценки организаций, их преимуществ и
                недостатков. Во многом благодаря рейтингу <strong>лучших Форекс Брокеров</strong> пользователи
                грамотно подбирают надежную структуру для входа на валютный рынок.</p></div>
        </div>
    );
};

export default Main;